/*
* Tooltips -  WIP
 */

var Tooltips = {
	init: function(){

		// wrap links in spans
		// setup even listeners
		// run showTip in response to mouseover events
		// run hideTip in response to mouseout events
		// run showTip in response to focus evens
		// run hideTip in response to blur evens
	},
	showTip: function(){
		// insert rich tooltip after the link
	},
	hideTip: function(){
		// remove rich tooltips after the link
	}
};

Tooltips.init();